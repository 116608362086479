<div class="modal-header">
	<h1 class="modal-title">
		@switch (systemLanguage) {
			@case ("de") {
				Website Wartungsarbeiten
			}
			@default {
				Website Maintenance
			}
		}
	</h1>
</div>

<div class="page-content p-a">
	<div class="content-inner">

		@switch (systemLanguage) {
			@case ("de") {
				<h2>Liebe Website-Besucher,</h2>
				<p>wegen Wartungsarbeiten an der Website, ist die melitta-professional.com aktuell nicht verfügbar.</p>
				<p>Hier im Planerhandbuch finden Sie alle Informationen zu unseren Produkten.
					Wenn Sie darüber hinaus Fragen haben oder Kontakt aufnehmen wollen, melden Sie sich gerne unter <a
						href="mailto:professional@melitta.de">professional&#64;melitta.de</a>.</p>
			}
			@default {
				<h2>Liebe Website-Besucher,</h2>
				<p>Due to maintenance work on the website, melitta-professional.com is currently not available. You can
					find all the information about our products here in the Sales&Planning manual.
					If you have any further questions or would like to get in touch, please contact us at <a
						href="mailto:professional@melitta.de">professional&#64;melitta.de</a>.
				</p>
			}
		}
	</div>
</div>

<div class="page-footer p-a">
	<button color="primary" mat-flat-button class="mat-small-button"
			(click)="close()">{{ 'close' | translate:systemLanguage }}
	</button>
</div>
